import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/button/button';
import { MinusCircle, PlusCircle } from '../icons/icons';
import { mergeClasses } from '../../../libs/helpers';
import './counter.scss';

const Counter = ({ className, start }) => {
  const ROOT_CLASS = 'counter';
  const ICON_CLASS = 'counter__icon';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  const [count, setCount] = useState(start);

  const handlerPlusClick = () => {
    setCount(count + 1);
  };

  const handlerMinusClick = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const PlusCircleIcon = <PlusCircle className={ICON_CLASS} onClick={handlerPlusClick} />;
  const MinusCircleIcon = <MinusCircle className={ICON_CLASS} onClick={handlerMinusClick} />;

  return (
    <Button
      iconLeft={MinusCircleIcon}
      iconRight={PlusCircleIcon}
      theme="white"
      borderRadius="sm"
      className={rootClass}
    >
      {count}
    </Button>
  );
};


Counter.propTypes = {
  className: PropTypes.string,
  start: PropTypes.number,
};

Counter.defaultProps = {
  className: '',
  start: 0,
};

export default Counter;
