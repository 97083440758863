import React from 'react';
import Icons from '../../icons/icons';

const GuiIcons = () => {
  const rootStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#7841C2',
  };

  return (
    <div id="icons" className="gui-section">
      <h2 className="gui-title">Иконки</h2>
      <div className="gui-body">
        <div style={rootStyle}>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {Icons.map((Icon, key) => <Icon key={key} />)}
        </div>
      </div>
    </div>
  );
};

export default GuiIcons;
