import React from 'react';
import Logo from '../../logo/logo';

const GuiLogo = () => (
  <div id="logo-section" className="gui-section">
    <Logo />
  </div>
);

export default GuiLogo;
