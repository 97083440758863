import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './switcher.scss';

const Switcher = (props) => {
  const {
    className, checked, name, changeChecked,
  } = props;

  const ROOT_CLASS = 'switcher';
  const INPUT_CLASS = 'switcher__input';
  const SLIDER_CLASS = 'switcher__slider';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  const [isChecked, setChecked] = useState(checked);

  function handleChecked() {
    const newChecked = !isChecked;

    setChecked(newChecked);
    changeChecked(newChecked);
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={rootClass}>
      <input
        className={INPUT_CLASS}
        type="checkbox"
        checked={isChecked}
        name={name}
        onChange={handleChecked}
      />
      <span className={SLIDER_CLASS} />
    </label>
  );
};

Switcher.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  changeChecked: PropTypes.func,
};

Switcher.defaultProps = {
  className: '',
  checked: false,
  changeChecked: () => {
  },
};

export default Switcher;
