import React from 'react';
import Button from '../../buttons/button/button';
import {
  IgIcon, SimpleArrow, User, PlusCircle,
} from '../../icons/icons';
import Counter from '../../counter/counter';

const GuiBtns = () => {
  const icon = <IgIcon className="header__btn-icon" />;
  const SimpleArrowRightIcon = React.createElement(SimpleArrow.right);
  const SimpleArrowLeftIcon = React.createElement(SimpleArrow.left);
  const SimpleArrowDownIcon = React.createElement(SimpleArrow.down);
  const SimpleArrowUpIcon = React.createElement(SimpleArrow.up);
  const UserIcon = React.createElement(User, {
    className: 'gui__btn-user-icon',
  });
  const PlusCircleIcon = <PlusCircle />;


  return (
    <div id="btns" className="gui-section">
      <div className="gui__row">

        <div className="gui__col">
          <Button theme="white" className="gui__btns-button">Записаться</Button>
        </div>

        <div className="gui__col">
          <Button iconRight={icon} theme="white" className="gui__btns-button">Записаться</Button>
        </div>

        <div className="gui__col">
          <Button iconLeft={icon} theme="white" className="gui__btns-button">Записаться</Button>
        </div>
      </div>

      <div className="gui__row">

        <div className="gui__col">
          <Button iconRight={SimpleArrowRightIcon} theme="black" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col">
          <Button iconRight={SimpleArrowLeftIcon} theme="black" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col">
          <Button iconRight={SimpleArrowDownIcon} theme="black" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col">
          <Button iconRight={SimpleArrowUpIcon} theme="black" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

      </div>

      <div className="gui__row">

        <div className="gui__col" style={{ flexBasis: 300 }}>
          <Button theme="red" className="gui__btns-button">Записаться</Button>
        </div>

      </div>

      <div className="gui__row">

        <div className="gui__col" style={{ width: 300 }}>
          <Button iconRight={SimpleArrowRightIcon} theme="white" outline className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col" style={{ width: 300 }}>
          <Button iconRight={SimpleArrowRightIcon} theme="black" outline className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col" style={{ width: 300 }}>
          <Button iconRight={SimpleArrowRightIcon} theme="red" outline className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

      </div>

      <div className="gui__row">

        <div className="gui__col">
          <Button theme="white" outline borderRadius="sm" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col">
          <Button theme="black" outline borderRadius="md" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col">
          <Button theme="black" outline borderRadius="lg" className="gui__btns-button">
            Смотреть больше
          </Button>
        </div>

        <div className="gui__col">
          <Button theme="red" borderRadius="extra" className="gui__btns-button">Смотреть больше</Button>
        </div>

      </div>

      <div className="gui__row">

        <div className="gui__col">
          <Button
            iconLeft={UserIcon}
            theme="purple"
            borderRadius="extra"
            className="gui__btns-button gui__btns-button_profile"
          >
            Профиль
          </Button>
        </div>

        <div className="gui__col">
          <Button theme="purple" borderRadius="sm" className="gui__btns-button button_check">Проверить</Button>
        </div>

      </div>

      <div className="gui__row">

        <div className="gui__col">
          <Button
            theme="black"
            outline
            borderRadius="extra"
            className="gui__btns-button button_small-text button_login"
          >
            Войти
          </Button>
        </div>

        <div className="gui__col">
          <Button
            iconLeft={PlusCircleIcon}
            theme="white"
            borderRadius="extra"
            className="gui__btns-button button_small-text button_add-animal"
          >
            Добавить питомца
          </Button>
        </div>

        <div className="gui__col">
          <Counter className="gui__btns-button button_counter" start={1} />
        </div>

      </div>
    </div>
  );
};

export default GuiBtns;
