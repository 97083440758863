import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './checkbox.scss';
import Icon from '../tz-icon/icon';

const CheckBox = (props) => {
  const {
    className, onChange, name, value, checked, children,
  } = props;

  const ROOT_CLASS = 'checkbox';
  const INPUT_CLASS = 'checkbox__input';
  const ICON_CLASS = 'checkbox__icon';
  const TEXT_CLASS = 'checkbox__text';

  const rootClass = mergeClasses(ROOT_CLASS, className);
  const textClass = mergeClasses(TEXT_CLASS, 'service-text');

  const [isChecked, setChecked] = useState(checked);

  const handleChange = () => {
    const newChecked = !isChecked;

    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={rootClass}>
      <input
        className={INPUT_CLASS}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <Icon mod="checkbox" className={ICON_CLASS} />
      <span className={textClass}>{children}</span>
    </label>
  );
};

CheckBox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.string,
};

CheckBox.defaultProps = {
  className: '',
  onChange: () => {},
  value: '',
  checked: '',
  children: '',
};

export default CheckBox;
