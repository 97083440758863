import React from 'react';

const Aside = () => (
  <div className="gui-aside">
    <div className="gui-aside__content">
      <div className="gui-aside__item"><a href="#typo" className="">Типографика</a></div>
      <div className="gui-aside__item"><a href="#par" className="">Абзац</a></div>
      <div className="gui-aside__item"><a href="#list" className="">Список</a></div>
      <div className="gui-aside__item"><a href="#logo-section" className="">Логотип</a></div>
      <div className="gui-aside__item"><a href="#icons" className="">Иконки</a></div>
      <div className="gui-aside__item"><a href="#contact" className="">Контактная ссылка</a></div>
      <div className="gui-aside__item"><a href="#avatar" className="">Аватары</a></div>
      <div className="gui-aside__item"><a href="#btns" className="">Кнопки</a></div>
      <div className="gui-aside__item"><a href="#form-elements" className="">Элементы формы</a></div>
    </div>
  </div>
);

export default Aside;
