import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import CheckBox from '../checkbox/checkbox';
import './policy-checkbox.scss';

const PolicyCheckBox = (props) => {
  const { className } = props;

  const ROOT_CLASS = 'policy-checkbox';
  const LINK_CLASS = 'checkbox__link';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  // TODO Alex Заменить ссылку на объект Link
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CheckBox {...props} className={rootClass}>
      Я согласен с
      <a href="http://localhost:8000/gui" className={LINK_CLASS}>
        условиями обработки персональных данных
      </a>
    </CheckBox>
  );
};

PolicyCheckBox.propTypes = {
  className: PropTypes.string,
};

PolicyCheckBox.defaultProps = {
  className: '',
};

export default PolicyCheckBox;
