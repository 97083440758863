import React from 'react';
import PropTypes from 'prop-types';
import RadioBox from '../../radiobox/radiobox';

// todo alex кажется здесь проблемы с логикой. Если это группа радиобоксов только для GUI то ок.
// Если обобщение, то надо переделать
const RadioGroup = (props) => {
  const { name, value } = props;

  const [currentValue, setValue] = React.useState(value);

  const handleChange = (event) => {
    setValue(event.target.value);
    return value;
  };

  return (
    <div>
      <RadioBox id={1} onChange={handleChange} currentValue={currentValue} value="first" name={name}>1</RadioBox>
      <br />
      <RadioBox id={2} onChange={handleChange} currentValue={currentValue} value="second" name={name}>2</RadioBox>
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

RadioGroup.defaultProps = {
  value: '',
};

export default RadioGroup;
