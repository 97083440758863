import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './radiobox.scss';

const RadioBox = (props) => {
  const {
    className, onChange, name, value, children, currentValue,
  } = props;

  const ROOT_CLASS = 'radiobox';
  const INPUT_CLASS = 'radiobox__input';
  const TEXT_CLASS = 'radiobox__text';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={rootClass}>
      <input
        className={INPUT_CLASS}
        type="radio"
        name={name}
        value={value}
        checked={value === currentValue}
        onChange={onChange}
      />
      <span className={TEXT_CLASS}>{children}</span>
    </label>
  );
};

RadioBox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
  currentValue: PropTypes.string,
};

RadioBox.defaultProps = {
  className: '',
  onChange: () => {},
  value: '',
  children: null,
  currentValue: '',
};

export default RadioBox;
