import React from 'react';
import GuiBoxControls from '../gui-box-controls/gui-box-controls';

const GuiFormElements = () => (
  <div id="form-elements">
    <GuiBoxControls />
  </div>
);

export default GuiFormElements;
