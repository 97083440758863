import React from 'react';
import Avatar from '../../avatar/avatar';

const GuiAvatar = () => {
  const rootStyle = {
    display: 'flex',
    alignItems: 'flex-end',
  };

  const accentAvatarStyle = {
    background: '#FFDB0D',
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
  };

  return (
    <div id="avatar" className="gui-section">
      <div style={rootStyle}>
        <Avatar size="lg" />
        <div style={accentAvatarStyle}>
          <Avatar size="md" border="white" />
        </div>
        <Avatar size="sm" />
      </div>
    </div>
  );
};

export default GuiAvatar;
