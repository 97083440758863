import React from 'react';
import '../components/gui/gui.scss';

import Aside from '../components/gui/gui-aside/gui-aside';
import Typo from '../components/gui/gui-typo/gui-typo';
import GuiLogo from '../components/gui/gui-logo/gui-logo';
import GuiIcons from '../components/gui/gui-icons/gui-icons';
import GuiContact from '../components/gui/gui-contact/gui-contact';
import GuiAvatar from '../components/gui/gui-avatar/gui-avatar';
import GuiBtns from '../components/gui/gui-btns/gui-btns';
import GuiFormElements from '../components/gui/gui-form-elements/gui-form-elements';

const Page = () => (
  <div className="body gui">

    <div className="page gui-page">

      <div className="gui-wrap">

        <Aside />

        <div className="gui-container">
          <Typo />
          <GuiLogo />
          <GuiIcons />
          <GuiContact />
          <GuiAvatar />
          <GuiBtns />
          <GuiFormElements />

        </div>
      </div>

    </div>

  </div>
);

export default Page;
