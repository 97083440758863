import React from 'react';
import {
  Mail as MailContact,
  Phone as PhoneContact,
  Mark as MarkContact,
  Ig as IgContact,
  Vk as VkContact,
  Fb as FbContact,
  Wa as WaContact,
  Vb as VbContact,
  Tg as TgContact,
} from '../../contacts/contacts';

const GuiContact = () => (
  <div id="contact" className="gui-section">

    <h2 className="gui-title">Контактная ссылка</h2>

    <div className="gui-body">

      <MailContact />
      <PhoneContact />
      <MarkContact />

      <div style={{ display: 'flex' }}>
        <IgContact />
        <VkContact />
        <FbContact />
      </div>

      <div style={{ display: 'flex' }}>
        <WaContact />
        <VbContact />
        <TgContact />
      </div>

    </div>
  </div>
);

export default GuiContact;
