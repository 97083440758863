import React from 'react';
import dataText from './data';

const Typo = () => (
  <div>
    <div id="typo" className="gui-section">
      <h2 className="gui-title">Типографика</h2>
      <div className="gui-body">
        <h1 className="page__title page__title_h1">Заголовок 1</h1>
        <h2 className="page__title page__title_h2">Заголовок 2</h2>
        <h3 className="page__title page__title_h3">Заголовок 3</h3>
        <h4 className="page__title page__title_h4">Заголовок 4</h4>
        <h5 className="page__title page__title_h5">Заголовок 5</h5>
        <h6 className="page__title page__title_h6">Заголовок 6</h6>
      </div>
      <br />
      <div className="text">Основной текст</div>
      <div className="service-text">Служебный текст</div>
      <div className="data-text">Данные</div>
      <div className="caption">Подписи</div>
      <div className=""><a href="/" className="link">Ссылка</a></div>
    </div>

    <div id="par" className="gui-section">
      <p className="par">{dataText.par}</p>
      <p className="par">{dataText.par}</p>
      <p className="par">{dataText.par}</p>
    </div>

    <div id="list" className="gui-section">

      <ol className="list list_num">

        <li className="list__item">
          <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
          <div className="list__item-body">{dataText.listItem}</div>
        </li>

        <li className="list__item">
          <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
          <div className="list__item-body">{dataText.listItem}</div>
        </li>

        <li className="list__item">
          <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
          <div className="list__item-body">{dataText.listItem}</div>
        </li>

        <li className="list__item">

          <ol className="list list_num">
            <li className="list__item">
              <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
              <div className="list__item-body">{dataText.listItem}</div>
            </li>

            <li className="list__item">
              <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
              <div className="list__item-body">{dataText.listItem}</div>
            </li>

            <li className="list__item">
              <ol className="list list_num">
                <li className="list__item">
                  <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
                  <div className="list__item-body">{dataText.listItem}</div>
                </li>

                <li className="list__item">
                  <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
                  <div className="list__item-body">{dataText.listItem}</div>
                </li>
              </ol>
            </li>

            <li className="list__item">
              <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
              <div className="list__item-body">{dataText.listItem}</div>
            </li>

          </ol>

        </li>

        <li className="list__item">
          <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
          <div className="list__item-body">{dataText.listItem}</div>
        </li>

        <li className="list__item">
          <div className="list__item-title page__title page__title_h2">Начисление персональных скидок</div>
          <div className="list__item-body">{dataText.listItem}</div>
        </li>

      </ol>

    </div>
  </div>
);

export default Typo;
