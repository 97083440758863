import React from 'react';
import Switcher from '../../switcher/switcher';
import GuiRadioGroup from '../gui-radio-group/gui-radio-group';
import PolicyCheckBox from '../../policy-checkbox/policy-checkbox';

const GuiBoxControls = () => {
  function changeChecked(checked) {
    // eslint-disable-next-line no-console
    console.log(checked);
  }

  return (
    <div>
      <div id="switcher" className="gui-section">
        <div className="gui-title">Переключатель</div>
        <div className="gui-body">
          <Switcher changeChecked={changeChecked} name="gui-switcher" />
        </div>
      </div>

      <div id="radioboxes" className="gui-section">
        <div className="gui-title">Радиобоксы</div>

        <GuiRadioGroup name="radio-group" />
      </div>

      <div id="checkboxes" className="gui-section">
        <div className="gui-title">Чекбоксы</div>

        <PolicyCheckBox>1</PolicyCheckBox>
      </div>
    </div>
  );
};

export default GuiBoxControls;
